import React, {useState} from 'react';
import Camera from './assets/Camera';
import logo from './assets/originate_logo.svg';
import loadingAnimation from './assets/loading_animation_infographic.gif';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/styles.scss';
import Reload from "./assets/Reload";

function App() {
    const [solvedImage, setSolvedImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const apiKey = process.env.REACT_APP_AZURE_API_KEY;
    const uploadLink = process.env.REACT_APP_AZURE_UPLOAD_LINK;


    const onChange = e => {
        e.preventDefault();
        const file = Array.from(e.target.files)[0];
        const formData = new FormData();
        formData.append('file', file);
        if (formData.get('file').size > 0) {

            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                title: "Are we allowed to save the image?",
                buttons: [
                    {
                        label: "Yes",
                        className: 'yes-btn button',
                        onClick: () => {
                            sendData(formData, true);
                        }
                    },
                    {
                        label: "No",
                        className: 'no-btn button',
                        onClick: () => {
                            sendData(formData, false);
                        }
                    }
                ]
            });

        }

    }

    function sendData(formData, shouldSaveImage) {
        setLoading(true);
        const urlWithParams = `${uploadLink}?saveImage=${shouldSaveImage}`
        fetch(urlWithParams, {
            method: 'POST',
            headers: {
                'x-functions-key': apiKey
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 422) {
                        return response.text().then(errorMessage => {
                            throw new Error(`${errorMessage}`);
                        });
                    } else if (response.status === 404) {
                        throw new Error(`404 - URL not correct. Please try later`);

                    } else {
                        throw new Error('Server returned an error');
                    }
                }

                const contentType = response.headers.get('Content-Type');

                if (contentType && contentType.startsWith('image/')) {
                    return response.blob();
                } else {
                    return response.text();
                }
            })
            .then(data => {
                if (data instanceof Blob) {
                    const imageUrl = URL.createObjectURL(data);
                    setSolvedImage(imageUrl);
                }
            })
            .catch(error => {
                setError(error.message)
            })
            .finally(() => setLoading(false))
    }

    const refresh = () => {
        setSolvedImage("")
        setError("")
    }

    return (
        <div className="app">
            <div className='app-header'>
                <h1 className='app-title'><a href="/">Sudoku Solver</a></h1>
                <div className='app-logo'>
                    <p className='app-logo__text'>developed &<br/>maintained by</p>
                    <a className='app-logo__link' href="https://originate.ch" target="_blank" rel="noreferrer" title="originate GmbH">
                        <img className='app-logo__image' src={logo} alt="Logo originate gmbh" width={80}/>
                    </a>
                </div>
            </div>
            <div className='app-content'>
                {error &&
                    <form className='app-content__container error-container'>
                        <div className='error-zone'>
                            <div className='error-message'>{error}</div>
                            <button className="retry-button button" onClick={refresh}>Try again</button>
                        </div>
                    </form>
                }
                {solvedImage === "" && !loading && error === "" &&
                    <form className='app-content__container drop-container'>
                        <label htmlFor="file-upload" className="dropzone">
                            <div className='drop-container__label'>
                                <Camera/>
                                upload image <br/> of a sudoku
                            </div>
                        </label>
                        <input type="file" id="file-upload" name="filename" accept="image/*" capture="camera"
                               onChange={onChange}/>
                    </form>
                }
                {solvedImage === "" && loading && error === "" &&
                    <div className='app-content__container loading-container'>
                        <img src={loadingAnimation} className="loading-animation" alt="Loading..."/>
                    </div>
                }

                {solvedImage !== "" && !loading && error === "" &&

                    <div className="app-content__container success-container">
                        <div className='image-container'>

                            <div className='reload-overlay'>
                                <Reload/>
                                <button className='reload-button button' onClick={refresh}>New Sudoku</button>
                            </div>

                            <img src={solvedImage} className="result-image" alt="Sudoku geloest"/>
                        </div>
                    </div>
                }
            </div>

        </div>


    );
}

export default App;
