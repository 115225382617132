import React from 'react'

const Camera = () => (
    <div className='camera'>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 100 100"
        >
            <path
                fill="#432d27"
                d="M70.833 8.333l5.859 8.788a8.324 8.324 0 006.933 3.712H100v70.834H0V20.833h24.708a8.327 8.327 0 006.934-3.712L37.5 8.333h33.333zM83.625 25a12.47 12.47 0 01-10.4-5.571L68.604 12.5H39.729l-4.621 6.933A12.47 12.47 0 0124.708 25H4.167v62.5h91.666V25H83.625zm-29.388 4.167c13.796 0 25.001 11.204 25.001 25-.001 13.796-11.205 25-25.001 25-13.795 0-24.999-11.204-24.999-25s11.204-25 25-25zm-.001 4.166c11.5 0 20.834 9.334 20.834 20.834S65.736 75 54.236 75c-11.499 0-20.833-9.333-20.833-20.833 0-11.5 9.334-20.834 20.834-20.834zm-.001 8.334c6.901 0 12.5 5.6 12.5 12.5.001 6.9-5.599 12.5-12.499 12.5s-12.5-5.6-12.5-12.5 5.6-12.5 12.5-12.5zm0 4.166c4.599 0 8.333 3.734 8.333 8.334S58.835 62.5 54.235 62.5s-8.334-3.733-8.334-8.333 3.734-8.334 8.334-8.334zM20.901 37.5c0-2.3-1.862-4.167-4.166-4.167a4.166 4.166 0 00-4.167 4.167c0 2.3 1.862 4.167 4.167 4.167a4.165 4.165 0 004.166-4.167zm-12.5-25h12.504v4.167H8.401V12.5z"
            ></path>
        </svg>
    </div>
)

export default Camera
